// Variables
$white-block-Width: 1460px;
$footer-Height: 780px;

.home {

  >div {
    overflow: hidden;
    position: relative;
  }

  .header {
    margin: 22px 22px 0 22px;
    overflow: hidden;

    .color {
      width: 100%;
      //height: calc(100vh - 22px);
      overflow: hidden;

      display: flex;
      flex-direction: column;
      justify-content: center;

      padding-top: 100px;
      padding-bottom: 150px;
      height: 100%;

      //aspect-ratio: 3/2;

      background: #F0EBF6;
      border-radius: 12px;

      .information {
        position: relative;
        height: 450px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        z-index: 1;

        h1 {
          font-size: 64px;
          font-weight: 600;
          word-wrap: break-word;
          text-align: center;

          color: transparent;
          background: linear-gradient(to bottom, #4201FE, #9954F0);
          -webkit-background-clip: text;
        }

        h2 {
          color: black;
          font-size: 28px;
          font-weight: 300;
          word-wrap: break-word;
          text-align: center;
        }

        .logo img {
          height: 100px;
        }

        .logo_app_store img {
          height: 50px;

          &:hover {
            opacity: .8;
          }
        }
      }

      .ellipse_first {
        position: absolute;
        width: 1188px;
        height: 1188px;

        top: -70vh;
        right: 0;

        border-radius: 1188px;
        background: radial-gradient(50% 50% at 50% 50%, rgba(66, 1, 254, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%);
      }

      .ellipse_second {
        position: absolute;
        width: 1744px;
        height: 1744px;

        bottom: -100vh;
        left: -30vw;

        border-radius: 1744px;
        background: radial-gradient(50% 50% at 50% 50%, rgba(153, 84, 240, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%);
      }

      .ellipse_third {
        position: absolute;
        width: 712px;
        height: 712px;

        top: 10vh;
        right: -10vw;

        border-radius: 712px;
        background: radial-gradient(50% 50% at 50% 50%, rgba(252, 19, 188, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%);
      }
    }
  }

  .after-header {
    position: relative;
    width: 100%;
    top: -100px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;

    padding: 0 100px;

    >div {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      width: 100%;
      height: 585px;

      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 18px;

      padding: 60px 50px;

      .video-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 0;
        border-radius: 18px;
      }

      .after-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background: linear-gradient(0deg, rgba(89, 53, 191, 0.82) 0%, rgba(89, 53, 191, 0.82) 100%);
        border-radius: 18px;
      }

      >div {
        z-index: 1;

        h5 {
          color: #FFF;
          font-size: 36px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          padding-bottom: 35px;
        }

        p {
          color: #FFF;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding-bottom: 50px;
        }

        a {
          display: inline-flex;
          padding: 16px 35px;
          justify-content: center;
          align-items: center;

          border-radius: 10px;
          background: #FFF;

          color: #4201FE;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-decoration: none;

          &:hover {
            opacity: .8;
          }
        }
      }
    }
  }

  .meet-fest-container {
    display: flex;
    flex-direction: row;
    padding: 0 150px 125px;

    >div {
      display: flex;
      flex-direction: column;
      gap: 50px;

      &:first-child {
        flex: 2;

        p {
          &:first-child {
            color: #4201FE;
            font-size: 26px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
          }

          &:nth-child(3) {
            color: #666;
            font-size: 18px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
          }
        }

        h3 {
          color: #000;
          max-width: 850px;

          font-size: 60px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      &:nth-child(2) {
        flex: 1;

        img {
          z-index: 1;
        }

        .ellipse-purple {
          position: absolute;
          width: 938px;
          height: 725px;
          border-radius: 938px;
          background: radial-gradient(50% 50% at 50% 50%, rgba(66, 1, 254, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%);
        }

        .ellipse-pink {
          position: absolute;
          width: 712px;
          height: 712px;
          border-radius: 712px;
          background: radial-gradient(50% 50% at 50% 50%, rgba(252, 19, 188, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%);

          margin: 100px 0 0 100px;
        }
      }
    }
  }

  .subscribe-container {
    padding: 0 100px 125px;
  }

  .registration-so-easy-container,
  .billing-container {
    display: flex;
    flex-direction: row;

    background: #F0EBF6;

    padding: 100px 150px;

    >div {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &:first-child {
        flex-direction: column;
        align-items: flex-start;

        h3 {
          color: transparent;
          font-size: 55px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;

          word-wrap: break-word;
          background: linear-gradient(to bottom, #4201FE, #9954F0);
          -webkit-background-clip: text;
        }

        p {
          color: #47454A;
          font-size: 22px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;

          padding: 55px 0 70px;
        }

        a {
          display: inline-flex;
          padding: 16px 35px;
          justify-content: center;
          align-items: center;
          gap: 10px;

          border-radius: 10px;
          background: #2D2839;

          color: #FFF;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-decoration: none;

          &:hover {
            opacity: .8;
          }
        }
      }

      &:nth-child(2) {
        img {
          z-index: 1;
          width: 100%;
        }

        .ellipses {
          position: relative;

          .ellipse {
            position: absolute;
            top: -150px;
            width: 800px;
            height: 800px;
            flex-shrink: 0;

            border-radius: 800px;
            background: radial-gradient(50% 50% at 50% 50%, rgba(66, 1, 254, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%);
          }
        }
      }

      .icons-container {
        display: flex;
        flex-direction: row;
        padding-left: 24px;
        padding-bottom: 65px;
        gap: 50px;

        >div {
          display: flex;
          align-items: center;
          gap: 25px;
        }
      }
    }
  }

  .public-private-container {
    display: flex;
    flex-direction: row;

    background: #FFFFFF;

    padding: 100px 150px;

    gap: 100px;

    >div {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &:first-child {
        img {
          z-index: 1;
          width: 100%;
        }
      }

      &:nth-child(2) {
        flex-direction: column;
        align-items: flex-start;

        h3 {
          width: 400px;
          color: transparent;
          font-size: 55px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;

          word-wrap: break-word;
          background: linear-gradient(to bottom, #4201FE, #9954F0);
          -webkit-background-clip: text;
        }

        p {
          color: #47454A;
          font-size: 22px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;

          padding: 55px 0 70px;
        }

        a {
          display: inline-flex;
          padding: 16px 35px;
          justify-content: center;
          align-items: center;
          gap: 10px;

          border-radius: 10px;
          background: #2D2839;

          color: #FFF;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          text-decoration: none;

          &:hover {
            opacity: .8;
          }
        }
      }

      .image-desktop { display: block; }
      .image-mobile { display: none; }
    }
  }

  .reviews-container {

    .labels {
      display: flex;
      flex-direction: row;
      align-items: center;

      padding: 120px 150px 50px;

      p {
        flex: 1;
        color: #000;

        font-size: 60px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      img {
        width: 95px;
        text-align: right;
      }
    }

    .reviews-horizontal {
      display: flex;
      flex-direction: row;
      gap: 20px;
      overflow-x: auto;

      padding: 0 150px 120px;

      &::-webkit-scrollbar {
        width: 0.1em;
      }

      .review-card {
        flex: 0 0 calc(50% - 10px);
        min-width: calc(50% - 10px);

        display: flex;
        flex-direction: row;
        min-height: 250px;

        border-radius: 30px;
        border: 1px solid rgba(66, 1, 254, 0.13);
        background: #FFF;
        box-shadow: 0px 17px 26px 0px rgba(0, 0, 0, 0.07);

        padding: 42px;

        .review-user {
          flex: 1;

          img {
            width: 75px;
            border-radius: 50%;
          }

          p {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            padding: 14px 0 8px;
          }

          span {
            color: #565656;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
          }
        }

        .review-info {
          flex: 3;
          padding-top: 5px;

          h5 {
            color: transparent;
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            word-wrap: break-word;
            background: linear-gradient(to bottom, #4201FE, #9954F0);
            -webkit-background-clip: text;
          }

          p {
            color: #292929;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 176.7%;

            padding-top: 30px;
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .after-header > div {
      height: 450px;
      padding: 50px 25px;

      >div {
        h5 {
          font-size: 30px;
          padding-bottom: 20px;
        }

        p {
          font-size: 18px;
          font-weight: 300;
          padding-bottom: 20px;
        }

        a {
          padding: 15px;
        }
      }
    }

    .meet-fest-container {
      flex-direction: row;
      align-items: center;
      padding: 30px;

      >div:first-child {
        gap: 30px;

        p:first-child {
          font-size: 24px;
        }

        h3 {
          font-size: 32px;
        }

        p:nth-child(3) {
          font-size: 18px;
        }
      }

      >div:nth-child(2) {
        position: relative;
        width: 50%;
        padding: 35px 0;

        img {
          padding: 25px;
        }

        .ellipse-purple {
          width: 600px;
          height: 500px;
          top: -50px;
          border-radius: 600px;
        }

        .ellipse-pink {
          width: 450px;
          height: 450px;
          top: 0;
          left: -250px;
          border-radius: 450px;
        }
      }
    }

    .subscribe-container {
      padding: 0 8px;

      .subscribe {
        padding: 50px;
      }
    }

    .registration-so-easy-container,
    .billing-container,
    .public-private-container,
    .reviews-container {
      flex-direction: row;
      padding: 70px 35px;
    }

    .registration-so-easy-container,
    .billing-container {
      >div {
        justify-content: center;
      }

      >div:first-child {
        z-index: 1;

        h3 {
          font-size: 30px;
        }

        p {
          font-size: 20px;
          padding: 35px 0;
        }

        a {
          margin-bottom: 75px;
        }

        .icons-container {
          gap: 15px;
          padding-left: 0;

          >div {
            gap: 10px;
          }
        }
      }

      .ellipses {
        display: none;
      }
    }

    .billing-container {
      >div:nth-child(2) .ellipses {
        display: flex;

        .ellipse {
          width: 600px;
          height: 600px;
          top: -250px;
          border-radius: 600px;
        }
      }
    }

    .public-private-container {
      flex-direction: row;
      gap: 50px;

      >div {
        .image-desktop { display: none; }
        .image-mobile { display: block; }
      }

      div:nth-child(2) {
        h3 {
          width: auto;
          font-size: 42px;
        }

        p {
          font-size: 18px;
          padding: 45px 0;
        }
      }
    }

    .reviews-container {
      padding: 0;

      .labels {
        flex-direction: column-reverse;
        top: -50px;
        padding: 70px 35px;

        p {
          font-size: 42px;
          text-align: center;
          padding-top: 30px;
        }
      }

      .reviews-horizontal {
        justify-content: flex-start;
        overflow-x: scroll;
        padding: 0 30px;

        .review-card {
          width: auto;
          min-width: 0;
          display: flex;
          flex-basis: calc(80vw - 10px);
          flex-direction: column;

          padding: 25px 20px;
          margin-bottom: 50px;

          img {
            float: left;
            margin-right: 25px;
          }

          .review-info {
            h5 {
              font-size: 26px;
              padding: 30px 0 20px;
            }

            p {
              font-size: 16px;
              padding: 0;
            }
          }
        }
      }
    }
  }

  @media (max-width: 880px) {
    .header {
      margin: 8px 8px 0 8px;

      .color {
        height: auto;
        padding: 0;

        .information {
          height: 100%;
          text-align: center;
          padding: 130px 35px;

          .logo img {
            height: 80px;
          }

          h1 {
            font-size: 43px;
            padding: 50px 0;
          }

          h2 {
            font-size: 22px;
            font-weight: 300;
            line-height: 160%;
            padding-bottom: 48px;
          }
        }
      }
    }

    .after-header {
      justify-content: flex-start;
      top: -50px;
      overflow-x: scroll;
      padding: 0 10px;

      >div {
        height: 500px;
      }

      >div:first-child {
        padding: 40px 30px;

        .after-info {
          width: calc(80vw - 60px);

          h5 {
            font-size: 36px;
          }

          p {
            font-size: 20px;
            font-weight: 300;
          }
        }
      }

      >div:not(:first-child) {
        padding: 60px calc(80vw / 2);
      }
    }

    .public-private-container {
      flex-direction: column-reverse;

      div:nth-child(2) {
        align-items: center;

        h3, p {
          text-align: center;
        }
      }
    }

    .registration-so-easy-container,
    .billing-container,
    .reviews-container,
    .meet-fest-container {
      flex-direction: column;
    }

    .meet-fest-container>div:nth-child(2) {
      width: 100%;
    }

    .registration-so-easy-container,
    .billing-container {
      >div {
        justify-content: center;
      }

      >div:first-child {
        align-items: center;

        h3, p {
          text-align: center;
        }
      }
    }
  }
}
