.custom-form {
  flex: 1;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: 18px;
  background: #F0EBF6;
  box-shadow: 21px 56px 117px 0px #F2EFF5;

  padding: 60px;

  form {
    width: 100%;

    label, .special-label {
      color: #351299;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      padding-bottom: 12px;
    }

    .inputs-two {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 20px;

      >div {
        flex: 1;
      }
    }

    .input-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;

      .input-error {
        border: 1px solid #FCC3C3;

        &::placeholder {
          color: #FCC3C3;
        }
      }

      .error {
        color: #D96262;
        padding: 10px 0 0;
      }
    }

    input, textarea {
      width: 100%;
      border: none;
      outline: none;

      border-radius: 10px;
      background: #FFFFFF;
      padding: 25px 45px 23px;

      &::placeholder {
        color: #B6ACCC;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
    }

    input[type="date"] {
      width: 100%;
    }

    textarea {
      height: 250px;
      resize: none;
    }

    .files-container {
      display: flex;
      flex-wrap: wrap;
      gap: 15px 35px;
      padding-bottom: 35px;

      .file {
        display: flex;
        flex-direction: row;
        gap: 12px;
      }
    }

    .button {
      display: flex;
      justify-content: center;

      &.space-between {
        justify-content: space-between;
        gap: 25px;
      }

      button {
        display: inline-flex;
        width: 350px;
        padding: 24px 35px;
        justify-content: center;
        align-items: center;

        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        border: none;
        border-radius: 10px;
        background: linear-gradient(176deg, #4201FE -58.26%, #9954F0 131.85%), #221F28;

        &:disabled {
          opacity: .7;
        }
      }

      .attach-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        border-radius: 10px;
        background: #EAE4F0;
        box-shadow: 21px 56px 117px 0px #F2EFF5;

        gap: 25px;
        padding: 28px 45px;

        span {
          color: #4201FE;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
  }

  @media (max-width: 1600px) {
    form {
      .inputs-two {
        display: block;
      }

      input, textarea {
        padding-left: 15px;
        padding-right: 15px;
      }

      .button.space-between {
        flex-direction: column;

        button {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    padding: 60px 35px;
  }

  @media (max-width: 800px) {
    form .button button {
      width: 100%;
    }
  }
}
