:root {
  --color-purple: #551274;
  --color-blue: #051653;
}

// Global Styles
html, body, button, input, optgroup, select, textarea {
  margin: 0;
  padding: 0;
  font: inherit;
  line-height: 1.15;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: 'DM Sans', sans-serif;
  overflow-x: hidden;
  scroll-behavior: smooth;
  transition: scroll-behavior 0.5s;
}

* {
  box-sizing: border-box;
}

div, p, h1, h2, h3, h4, h5, a {
  margin: 0;
}

.loader {
  display: flex;
  justify-content: center;
  padding: 200px 0 100px;
}

.not-scroll {
  overflow-y: hidden;
  height: 100vh;
}
