.menu-container {
  position: absolute;
  width: 100%;
  top: 40px;
  padding: 0 22px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .image {
    width: 200px;

    &:nth-child(1) {
      img {
        width: 55px;

        background-color: transparent;
        box-shadow: 0 24.60839080810547px 37.6363639831543px rgba(66, 1, 254, 0.19);
      }
    }

    &.right {
      text-align: right;
    }
  }

  .menu-top {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 0 22px;

    z-index: 1;

    &.white {
      padding: 20px 20px;
      background: #fff;
      border-radius: 20px;

      .white ul li > a {
        color: #615976;
      }
    }

    .menu-mobile {
      display: none;
    }

    .menu-top-container {
      width: 100%;
      max-width: 1000px;
      display: flex;
      justify-content: center;

      ul {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        list-style: none;
        margin: 5px 0;
        padding: 0;

        li >a {
          color: #615976;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          text-decoration: none;

          &:hover, &.active {
            color: #4201FE;
          }

          img {
            height: 80px;
          }
        }
      }
    }

    .white {
      ul li > a {
        color: #fff;
      }
    }
  }
}

.menu-mobile-left {
  display: none;
}

@media (max-width: 800px) {
  .menu-container {
    top: 30px;
    z-index: 2;

    .image.right {
      width: auto;
      img {
        height: 40px;
      }
    }

    .menu-top {
      padding: 0 10px;

      &.white {
        padding: 0;
        background: none;
        border-radius: 0;
      }

      .logo-menu-top {
        display: none;
      }

      .menu-top-container {
        ul {
          display: none;
        }
      }

      .menu-mobile {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .btn {
          display: flex;
          flex-direction: row;
          padding-right: 15px;

          img {
            width: 33px;
          }

          span {
            color: var(--2ndry, #121212);
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            padding-left: 15px;
          }
        }
      }
    }
  }

  .menu-mobile-left {
    position: absolute;
    display: block;
    width: 100vw;
    height: 100vh;
    top: 0;
    background-color: rgba(14, 5, 29, 0.85);
    z-index: 2;

    .menu-mobile-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100vh;
      max-width: 360px;
      background-color: #F0EBF6;

      padding: 30px;

      .menu-mobile-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .logo-menu-top > img {
          width: 48px;
        }

        .btn {
          display: flex;
          align-items: center;
          gap: 20px;

          span {
            color: #615976;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }

      .menu-mobile-center {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 10px;

        a {
          color: #615976;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-decoration: none;

          border-radius: 10px;
          background: rgba(255, 255, 255, 0.24);

          padding: 12px 22px;

          &.active {
            color: #4201FE;
            background: #FFFFFF;
          }
        }
      }

      .menu-mobile-bottom {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 8px;

        .socialBtn {
          width: 55px;
          height: 55px;

          display: flex;
          align-items: center;
          justify-content: center;

          border-radius: 10px;
          background: #FFFFFF;

          &:hover {
            opacity: .8;
          }

          img {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }

  .show-animation-menu {
    display: block;
  }

  .hide-animation-menu {
    display: none;
  }
}
