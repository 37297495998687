.subscribe {
  background-color: #28272D;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  border-radius: 12px;

  padding: 65px 100px;

  overflow: hidden;

  .subscribe-content {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 100px;

    width: 100%;

    z-index: 1;

    .mail-pic img {
      width: 200px;
    }

    .mail-info {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 38px;

      h5 {
        color: #FFF;
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      p {
        color: #FFF;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }

      .input-container {
        display: flex;
        align-items: center;

        .email-input {
          flex: 1;
          color: #B6ACCC;
          font-size: 18px;
          font-style: normal;
          font-weight: 300;

          border-radius: 10px 0 0 10px;
          background: rgba(214, 214, 231, 0.10);
          border: none;
          padding: 18px 35px;
        }

        .submit-button {
          display: inline-flex;
          margin-left: -10px;
          padding: 18px 35px;
          justify-content: center;
          align-items: center;

          color: #FFF;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          border: none;
          border-radius: 10px;
          background: linear-gradient(176deg, #4201FE -58.26%, #9954F0 131.85%), #221F28;
        }
      }
    }
  }

  .ellipses {
    position: relative;

    .ellipse_left {
      position: absolute;
      width: 1000px;
      height: 1000px;

      top: -500px;
      left: -150px;

      transform: rotate(15deg);
      flex-shrink: 0;
      border-radius: 1000px;
      background: radial-gradient(50% 50% at 50% 50%, rgba(153, 84, 240, 0.40) 0%, rgba(40, 39, 45, 0.00) 100%);
    }

    .ellipse_right {
      position: absolute;
      width: 500px;
      height: 500px;

      top: -70px;
      right: -150px;

      flex-shrink: 0;
      border-radius: 500px;
      background: radial-gradient(50% 50% at 50% 50%, rgba(66, 1, 254, 0.40) 0%, rgba(40, 39, 45, 0.00) 100%);
    }
  }

  @media (max-width: 800px) {
    padding: 50px 30px;

    .subscribe-content {
      flex-direction: column;
      align-items: flex-start;
      gap: 25px;

      .mail-pic img {
        width: 135px;
      }

      .mail-info {
        .input-container {
          flex-direction: column;
          gap: 20px;

          .email-input {
            width: 100%;
            border-radius: 10px 10px 10px 10px;
            margin-left: 0;
            padding: 25px 25px;
          }

          .submit-button {
            width: 100%;
            margin: 0;
            padding: 25px 25px;
          }
        }
      }
    }
  }
}
