.modal {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(14, 5, 29, 0.85);
  overflow: hidden;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  z-index: 2;

  padding: 50px 0;

  .modal-container {
    width: 920px;
    position: relative;
    display: table;
    border-radius: 10px;
    background: #F0EBF6;
    box-shadow: 21px 56px 117px 0 #302440;

    padding: 55px 60px;

    .close {
      position: absolute;
      right: 50px;
      top: 50px;

      img {
        width: 15px;
        height: 15px;
      }
    }

    p {
      color: #3B373F;
      font-size: 20px;
      font-weight: 300;
    }

    h3 {
      color: #4201FE;
      font-size: 40px;
      font-weight: 600;

      padding: 20px 0 35px;
    }

    .custom-form {
      box-shadow: none;
      padding: 0;

      .terms {
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
        gap: 30px;

        .custom-checkbox {
          >div {
            display: flex;
            flex-direction: row;
            gap: 25px;

            img {
              cursor: pointer;
            }

            .label {
              color: #3B373F;
              font-size: 18px;
              font-weight: 400;

              .description {
                color: #4201FE;
                font-size: 18px;
                font-weight: 400;
              }
            }
          }
        }
      }
    }

    .status-container {
      display: flex;
      flex-direction: column;
      gap: 30px;

      >div, p {
        color: #3B373F;
        font-size: 20px;
        font-weight: 400;
      }

      .qr-code {
        margin: 0 auto;
      }

      .status {
        span {
          color: #4201FE;
        }
      }

      .button {
        display: inline-flex;
        justify-content: center;
        align-items: center;

        a {
          color: #FFF;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border: none;
          border-radius: 10px;
          background: linear-gradient(176deg, #4201FE -58.26%, #9954F0 131.85%), #221F28;
          text-decoration: none;
          padding: 15px 35px;

          &:hover {
            opacity: .8;
          }
        }
      }
    }
  }

  @media (max-width: 800px) {
    padding-left: 5px;
    padding-right: 5px;

    .modal-container {
      padding: 25px 25px;

      h3 {
        font-size: 28px;
      }
    }
  }
}
