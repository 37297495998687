.features {

  >div {
    overflow: hidden;
    position: relative;
  }

  .header {
    margin: 0;
    overflow: hidden;

    .color {
      width: 100%;
      //height: calc(100vh + 200px);
      overflow: hidden;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      padding-top: 200px;
      padding-bottom: 400px;
      height: 100%;

      //aspect-ratio: 3/2;

      background: #FFFFFF;
      border-radius: 12px;

      .information {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;

        .information-container {
          position: relative;
          height: 450px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          gap: 50px;
          z-index: 1;

          h1 {
            color: #000000;
            font-size: 64px;
            font-weight: 600;
            word-wrap: break-word;
            text-align: center;
          }

          h2 {
            max-width: 1200px;

            color: #000000;
            font-size: 28px;
            font-weight: 300;
            word-wrap: break-word;
            text-align: center;
          }

          .logo img {
            height: 100px;
          }

          .logo_app_store img {
            height: 50px;

            &:hover {
              opacity: .8;
            }
          }
        }
      }

      .ellipse_first {
        position: absolute;
        width: 1100px;
        height: 1100px;

        top: -20vh;
        right: -50vh;

        border-radius: 1100px;
        background: radial-gradient(50% 50% at 50% 50%, rgba(19, 196, 252, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
      }

      .ellipse_second {
        position: absolute;
        width: 1050px;
        height: 1050px;

        top: -10vh;
        left: -20vw;

        border-radius: 1050px;
        background: radial-gradient(50% 50% at 50% 50%, rgba(134, 84, 240, 0.10) 0%, rgba(255, 255, 255, 0.00) 100%);
      }

      .ellipse_third {
        position: absolute;
        width: 700px;
        height: 700px;

        top: 40vh;
        right: 10vw;

        border-radius: 700px;
        background: radial-gradient(50% 50% at 50% 50%, rgba(66, 1, 254, 0.10) 0%, rgba(255, 255, 255, 0.00) 100%);
      }

      .ellipse_fourth {
        position: absolute;
        width: 1000px;
        height: 1000px;

        bottom: -30vh;
        left: 0;

        border-radius: 1000px;
        background: radial-gradient(50% 50% at 50% 50%, rgba(84, 184, 240, 0.45) 0%, rgba(255, 255, 255, 0.00) 100%);
      }
    }
  }

  .white-containers {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    top: -300px;
    margin-bottom: -300px;
    padding: 0 100px 100px;

    .white-container {
      display: flex;
      flex-direction: row;
      align-items: stretch;

      border-radius: 18px;
      background: #FFF;
      box-shadow: 21px 56px 117px 0px #F2EFF5;

      padding: 60px 80px;
      margin-bottom: 70px;
      gap: 25px;

      &.reverse {
        flex-direction: row-reverse;
      }

      .img-container {
        position: relative;
        text-align: right;

        &.left {
          text-align: left;
          display: flex;
          align-items: center;
        }

        .video {
          position: absolute;
          right: 230px;
          width: 220px;
          height: 500px;
        }

        img {
          position: relative;

          &.round {
            border-radius: 18px;
          }
        }

        .video-background {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          border-radius: 6px;
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.18) 0%, rgba(255, 255, 255, 0.18) 100%);
        }
      }

      >div {
        flex: 1;
        text-align: center;

        &:first-child {
          display: flex;
          flex-direction: column;
          text-align: left;
          gap: 45px;

          h3 {
            color: transparent;
            background: linear-gradient(to bottom, #4201FE, #9954F0);
            -webkit-background-clip: text;


            font-size: 40px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }

          p {
            color: #47454A;
            font-size: 18px;
            font-style: normal;
            font-weight: 300;
            line-height: 180%;

            &:first-child {
              color: #585858;
              font-size: 24px;
              font-style: normal;
              font-weight: 300;
              line-height: normal;
            }
          }
        }

        .icons-container {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(10px, 250px));
          grid-gap: 20px;

          >div {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 18px;
            padding-bottom: 25px;

            img {
              height: 20px;
            }

            span {
              color: #221F26;
              font-size: 18px;
              font-style: normal;
              font-weight: 600;
              line-height: 180%;
            }
          }
        }
      }
    }

    .logo_app_store {
      text-align: center;
    }

    .ellipse {
      position: absolute;
      width: 1100px;
      height: 635px;

      border-radius: 1100px;
      background: radial-gradient(50% 50% at 50% 50%, rgba(66, 1, 254, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%);

      z-index: -1;

      &.left {
        bottom: -300px;
        left: -20vw;
      }

      &.right {
        bottom: 100px;
        right: -20vw;
      }
    }
  }

  @media (max-width: 1720px) {
    .white-containers {
      padding: 0 50px 100px;
    }
  }

  @media (max-width: 1600px) {
    .white-containers {
      padding: 0 10px 100px;

      .white-container {
        padding: 30px 20px;
        gap: 10px;
      }
    }
  }

  @media (max-width: 1450px) {
    .white-containers {
      padding: 0 10px 100px;

      .white-container {
        padding: 30px 20px;
        gap: 10px;
      }
    }
  }

  @media (max-width: 1200px) {
    .white-containers {
      padding: 0 10px 100px;

      .white-container {
        padding: 30px 20px;
        gap: 10px;

        >div > .icons-container {
          grid-template-columns: repeat(auto-fill, 250px);
          gap: 5px;
        }

        >div:first-child {
          gap: 15px;

          h3 {
            font-size: 28px;
            font-weight: 600;
          }

          p {
            font-size: 16px;
            font-weight: 300;
            line-height: 180%;

            &:first-child {
              font-size: 20px;
              font-weight: 300;
            }
          }
        }

        .img-container {
          width: 100%;
          display: grid;
          align-items: center;

          img {
            width: 100%;

            &.round {
              border-radius: 18px;
            }
          }

          .video {
            width: calc(100vw / 6);
            height: calc(100vw / 2.8);
            right: 37%;
          }
        }
      }
    }
  }

  @media (max-width: 820px) {
    .header .color {
      padding-top: 200px;

      .information .information-container {
        padding: 0 10px;

        h1 {
          font-size: 48px;
        }

        h2 {
          font-size: 22px;
        }
      }
    }

    .white-containers .white-container {
      flex-direction: column;

      &.reverse {
        flex-direction: column;
      }

      .img-container {
        .video {
          width: calc(100vw / 3);
          height: calc(100vw / 1.45);
          right: 37%;
        }
      }

      >div .icons-container {
        grid-template-columns: repeat(auto-fill, 160px);

        >div {
          span {
            font-size: 16px;
          }

          img {
            height: 18px;
          }
        }
      }
    }
  }
}
