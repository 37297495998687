.register_interest {
  position: relative;
  overflow: hidden;

  .ellipse-container {
    position: relative;
    z-index: -1;

    >div {
      position: absolute;
    }

    .ellipse-first {
      left: -800px;
      top: -500px;

      width: 1750px;
      height: 1750px;
      border-radius: 1750px;
      background: radial-gradient(50% 50% at 50% 50%, rgba(240, 84, 131, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%);
    }

    .ellipse-second {
      top: 600px;

      width: 1050px;
      height: 1050px;
      border-radius: 1050px;
      background: radial-gradient(50% 50% at 50% 50%, rgba(134, 84, 240, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%);
    }

    .ellipse-third {
      right: -800px;
      top: -1000px;

      width: 1750px;
      height: 1750px;
      border-radius: 1750px;
      background: radial-gradient(50% 50% at 50% 50%, rgba(240, 84, 131, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%);
    }
  }

  .register-container {
    display: flex;
    flex-direction: row;
    gap: 110px;

    padding: 200px 100px 100px;

    .information {
      flex: 1;
      padding-top: 100px;

      h1 {
        font-size: 64px;
        font-weight: 600;
        word-wrap: break-word;
        color: transparent;
        background: linear-gradient(to bottom, #4201FE, #9954F0);
        -webkit-background-clip: text;

        padding-bottom: 50px;
      }

      h2 {
        color: #202020;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 190%;

        padding-bottom: 45px;
      }

      p {
        color: #383838;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 190%;

        padding-bottom: 45px;
      }

      .exclusives {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 35px;

        p {
          width: 100%;

          color: #7000FF;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          text-transform: capitalize;
          text-align: center;

          border-radius: 18px;
          background: #F0EBF6;
          box-shadow: 21px 56px 117px 0 #F2EFF5;

          padding: 20px 30px;
        }

        span {
          color: #383838;
          font-size: 20px;
          font-style: normal;
          font-weight: 300;
        }
      }
    }
  }

  @media (max-width: 1600px) {
    .register-container {
      .information {
        h1 {
          font-size: 48px;
          padding-bottom: 35px;
        }

        h2 {
          font-size: 22px;
          padding-bottom: 35px;
        }

        p {
          font-size: 18px;
          padding-bottom: 40px;
        }

        .exclusives {
          flex-direction: column;
          gap: 15px;

          p {
            width: fit-content;
            font-size: 18px;

            padding: 15px 30px;
          }

          span {
            font-size: 18px;
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .register-container {
      padding: 200px 25px 100px;
      gap: 25px;
    }
  }

  @media (max-width: 800px) {
    .register-container {
      flex-direction: column;
      padding: 50px 25px 100px;

      .information {
        padding-bottom: 50px;
      }
    }
  }
}
