.articles {
  position: relative;
  overflow: hidden;

  .ellipse-container {
    position: relative;
    z-index: -1;

    >div {
      position: absolute;
    }

    .ellipse-first {
      left: -800px;
      top: -500px;

      width: 1750px;
      height: 1750px;
      border-radius: 1750px;
      background: radial-gradient(50% 50% at 50% 50%, rgba(240, 84, 131, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%);
    }

    .ellipse-second {
      top: 600px;

      width: 1050px;
      height: 1050px;
      border-radius: 1050px;
      background: radial-gradient(50% 50% at 50% 50%, rgba(134, 84, 240, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%);
    }

    .ellipse-third {
      right: -800px;
      top: -1000px;

      width: 1750px;
      height: 1750px;
      border-radius: 1750px;
      background: radial-gradient(50% 50% at 50% 50%, rgba(240, 84, 131, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%);
    }
  }

  .information {
    display: flex;
    flex-direction: row;
    justify-content: center;

    margin-top: 200px;

    .information-container {
      .logo {
        text-align: center;
        padding-bottom: 50px;
      }

      h1 {
        color: #000;
        font-size: 64px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: center;
      }

      .information-content {
        max-width: 850px;
        padding: 100px 25px;

        >div {
          padding-bottom: 35px;

          h5 {
            color: #000;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 190%;
          }

          p, li {
            color: #383838;
            font-size: 18px;
            font-style: normal;
            font-weight: 300;
            line-height: 35px;

            &:not(li) {
              padding-top: 25px;
            }
          }

          img {
            width: 100%;
          }

          a {
            color: #4201FE;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 35px;
            text-decoration: none;
          }
        }
      }
    }
  }

  @media (max-width: 800px) {
    .information .information-container h1 {
      font-size: 40px;
    }
  }
}
