.party-view {
  background: #F2F0F5;
  padding: 180px 100px 50px;

  .party-container {
    position: relative;


    .background-white {
      position: relative;
      display: flex;

      width: 100%;
      border-radius: 18px;
      background: #FFF;
      box-shadow: 21px 56px 117px 0px #F2EFF5;

      gap: 80px;
      padding: 65px 80px;

      >div {
        flex: 1;
      }

      .left-container {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 40px;

        .first-block {
          display: flex;
          justify-content: space-between;

          .host-info {
            display: flex;
            align-items: center;
            gap: 10px;

            img {
              width: 40px;
              height: 40px;
              border-radius: 40px;
            }

            >div {
              display: flex;
              flex-direction: column;
              gap: 5px;

              p {
                color: #121212;
                font-size: 14px;
                font-weight: 600;
              }

              div {
                display: flex;
                align-items: center;
                gap: 5px;

                p {
                  color: #585858;
                  font-weight: 300;
                }

                .rate-host {
                  gap: 2px;

                  img {
                    width: 14px;
                    height: 14px;
                  }
                }
              }
            }
          }

          .members {
            display: flex;
            flex-direction: row;

            img {
              width: 40px;
              height: 40px;

              border-radius: 40px;
              border: 1.5px solid #FFF;

              margin-right: -10px;
            }

            div {
              width: 40px;
              height: 40px;

              display: flex;
              align-items: center;
              justify-content: center;

              color: #FFFFFF;
              font-size: 13px;
              font-weight: 400;
              background: linear-gradient(179deg, rgba(66, 1, 254, 0.90) -44.3%, rgba(153, 84, 240, 0.90) 99.24%);
              border: 2px solid #FFFFFF;
              filter: drop-shadow(0px 10px 17px rgba(176, 148, 255, 0.8));
              border-radius: 40px;
            }
          }
        }

        .title {
          color: #121212;
          font-size: 40px;
          font-weight: 600;
        }

        .second-block {
          display: flex;
          flex-direction: row;
          gap: 25px;

          >div {
            flex: 1;
          }

          .information {
            display: flex;
            flex-direction: column;
            gap: 15px;

            >div {
              display: flex;
              flex-direction: row;
              align-items: center;

              gap: 18px;

              span {
                color: #121212;
                font-size: 16px;
                font-weight: 400;
              }
            }

            .location > a {
              color: #4A20F0;
              font-weight: 500;
            }
          }

          .description {
            h5 {
              color: #121212;
              font-size: 18px;
              font-weight: 700;

              padding-bottom: 18px;
            }

            >div {
              max-height: 300px;
              overflow-y: auto;

              color: #585858;
              font-size: 16px;
              font-weight: 300;
              line-height: 22px;

              padding-right: 25px;

              &::-webkit-scrollbar {
                width: 6px;
              }

              &::-webkit-scrollbar-track {
                border-radius: 10px;
                background: #F1F3F4;
              }

              &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background: #000;
              }
            }
          }
        }
      }

      .right-container {
        .tickets-container {
          display: grid;
          width: 100%;
          background-color: #F9F9FF;
          border-radius: 10px;

          padding: 15px;

          p {
            color: #756C82;
            font-size: 14px;
            font-weight: 300;
            text-transform: capitalize;
          }

          .tickets {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 10px;
            padding: 15px 0 5px;
            overflow-x: auto;

            .ticket {
              min-width: 215px;
              display: flex;
              flex-direction: column;
              border-radius: 10px;
              background: #FFF;
              box-shadow: 0px 10px 8px 0 #eae8ec;
              gap: 10px;
              padding: 20px 12px 12px;

              margin-bottom: 10px;

              .title {
                width: 190px;
                color: #4201FE;
                font-size: 20px;
                font-weight: 600;
                text-transform: capitalize;
              }

              .count {
                color: #9689BB;
                font-size: 15px;
                font-weight: 300;
                line-height: 22px;
              }

              .price {
                color: #000;
                font-size: 24px;
                font-weight: 600;
                text-transform: capitalize;
              }

              .button {
                display: inline-flex;
                width: 190px;
                padding: 15px 35px;
                justify-content: center;
                align-items: center;
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                border: none;
                border-radius: 10px;
                background: linear-gradient(176deg, #4201FE -58.26%, #9954F0 131.85%), #221F28;
              }
            }
          }
        }

        .google-map {
          position: relative !important;
          height: 450px !important;
          margin-top: 25px;
          margin-bottom: 50px;

          >div {
            border-radius: 10px;
          }

          .gmnoprint {
            display: none;
          }

          .btn-navigate {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            position: absolute;
            border-radius: 6px;
            background: #FFF;
            text-decoration: none;

            bottom: 10px;
            right: 10px;
            gap: 20px;
            padding: 13px 15px;

            &:hover {
              opacity: .8;
              cursor: pointer;
            }

            p {
              height: 15px;
              color: #4201FE;
              font-size: 16px;
              font-weight: 600;
              text-transform: capitalize;
            }
          }
        }
      }
    }

    .purple-border-top {
      position: absolute;
      top: -5px;
      width: 100%;
      height: 160px;
      border-radius: 18px;
      background: linear-gradient(176deg, #4201FE -58.26%, #9954F0 131.85%), #4201FE;
      box-shadow: 21px 56px 117px 0px #F2EFF5;
    }
  }

  .react-horizontal-scrolling-menu--scroll-container {
    overflow-x: hidden;
  }

  .react-horizontal-scrolling-menu--inner-wrapper {
    position: relative;
  }

  .react-horizontal-scrolling-menu--arrow-right {
    position: absolute;
    right: 0;
  }

  .react-horizontal-scrolling-menu--arrow-left {
    position: absolute;
    left: 0;
    z-index: 1;
  }

  .react-horizontal-scrolling-menu--item {
    >div {
      width: 200px;
      height: 200px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .gallery-separator {
    padding: 5px;
  }

  .gallery-arrow {
    display: flex;
    height: 220px;
    background-color: #ffffffcc;
    padding: 0 20px;

    &.reverse {
      transform: rotate(180deg);
    }

    img {
      width: 20px;
    }
  }

  .go-home {
    position: relative;
    display: flex;
    justify-content: center;

    margin-top: 50px;

    a {
      display: flex;
      color: #FFFFFF;
      border-radius: 10px;
      background: #2D2839;
      text-decoration: none;

      padding: 16px 35px;

      &:hover {
        opacity: .8;
      }
    }
  }


  @media (max-width: 1500px) {
    padding: 180px 0 50px;

    .party-container .background-white {
      padding: 65px 25px;
      gap: 25px;
    }
  }


  @media (max-width: 1100px) {
    .party-container .background-white .left-container .second-block {
      flex-direction: column;
    }
  }

  @media (max-width: 800px) {
    padding: 100px 0 50px;

    .party-container {
      .purple-border-top {
        display: none;
      }

      .background-white {
        flex-direction: column;
        border-radius: 0;
        padding-bottom: 0;

        .left-container {
          width: 100%;

          > div:last-child {
            order: -1;
          }

          .title {
            font-size: 22px;
          }
        }
      }
    }
  }
}
