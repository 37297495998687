.footer {
  .subscribe {
    background-color: #1A0C43;
    border-radius: 0;

    padding: 65px 200px;
  }

  .footer-container-first {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    background-color: #1C103C;

    padding: 35px 50px;

    >div {
      align-items: center;
    }

    .image {
      display: flex;
      align-items: center;

      img {
        height: 55px;
      }
    }

    ul {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0;
      gap: 80px;

      list-style: none;

      a {
        color: rgba(255, 255, 255, .9);
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-decoration: none;

        &:hover {
          opacity: .8;
        }
      }
    }

    .socials {
      display: flex;
      flex-direction: row;
      gap: 10px;

      .image.logo {
        display: none;
      }

      .socialBtn {
        width: 55px;
        height: 55px;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 10px;
        background: rgba(255, 255, 255, 0.16);

        &:hover {
          opacity: .8;
        }

        img {
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  .footer-container-second {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 70px;

    background: #130C25;

    padding: 30px 50px;

    a {
      color: #9954F0;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      text-decoration: none;

      &:hover {
        opacity: .8;
      }
    }
  }

  @media (max-width: 1200px) {
    .footer-container-first .socials {
      display: none;
    }

    .subscribe {
      padding: 50px 30px;
    }
  }

  @media (max-width: 800px) {

    .footer-container-first {
      flex-direction: column-reverse;
      padding: 25px;

      .footer-menu {
        padding: 25px 0;
        ul {
          flex-direction: column;
          align-items: flex-start;
          gap: 22px;
          padding: 0;
        }

        a.image {
          display: none;
        }
      }

      .socials {
        justify-content: center;

        .image.logo {
          display: flex;
        }
      }
    }

    .footer-container-second {
      flex-direction: column-reverse;
      align-items: flex-start;
      gap: 22px;
      padding: 25px;
    }
  }
}
