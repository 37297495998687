.support_form {
  @media (max-width: 1400px) { }

  @media (max-width: 950px) { }
}

.support_view {

  .button-purple {
    display: none;
  }

  .register-container .information h1 {
    padding-bottom: 18px;
  }

  .support-view-information {

    display: flex;
    flex-direction: column;
    gap: 22px;

    >div {
      display: flex;
      flex-direction: column;
      color: #6B5CA7;
      font-size: 16px;
      font-weight: 300;
      line-height: normal;
      gap: 10px;

      &.number {
        color: #6B5CA7;
        font-size: 18px;
        font-weight: 300;
        line-height: 26px;

        padding-bottom: 8px;
      }

      &.status {
        color: #4201FE;
        font-weight: 400;
      }

      label {
        display: block;
        color: #3C334F;
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
      }
    }
  }

  .custom-form {
    max-height: 850px;
    justify-content: space-between;
    padding: 30px 60px;

    form textarea {
      height: 200px;
    }

    .messages-container {
      max-height: 370px;
      width: 100%;
      display: flex;
      flex-direction: column;

      overflow-y: scroll;

      gap: 20px;
      padding-bottom: 15px;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: rgba(255, 255, 255, .5);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #000;
      }

      .message {
        display: flex;
        flex-direction: column;

        border-radius: 10px;
        background: #E8E2F0;

        gap: 20px;
        padding: 25px 50px;

        .user-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 15px;

          &.reply {
            justify-content: end;
          }

          img {
            height: 35px;
            width: 35px;
          }

          >div {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .name {
              color: #4A20F0;
              font-size: 16px;
              font-weight: 400;
            }

            .date {
              color: #8B7FBB;
              font-size: 14px;
              font-weight: 300;
            }
          }
        }

        .description {
          color: #3C334F;
          font-size: 18px;
          font-weight: 300;
          line-height: 26px;
        }

        .files {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .file {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-decoration: none;
            gap: 15px;

            span {
              color: #4A20F0;
              font-size: 16px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1500px) {
    .register-container {
      padding: 200px 50px 100px;
    }

    .custom-form {
      padding: 30px;
    }
  }

  @media (max-width: 1000px) {
    .custom-form {
      display: none;
    }

    .register-container {
      padding: 200px 25px 50px;

      .information {
        padding-top: 0;
      }
    }

    .button-purple {
      display: flex;
      justify-content: center;
      margin-bottom: 70px;

      div {
        display: inline-flex;
        width: 350px;
        justify-content: center;
        align-items: center;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border: none;
        border-radius: 10px;
        background: linear-gradient(176deg, #4201FE -58.26%, #9954F0 131.85%), #221F28;
        padding: 24px 35px;
      }
    }

    .mobile-modal {
      position: fixed;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      background-color: #F0EBF6;
      z-index: 5;

      .header {
        background: #F0EBF6;
        box-shadow: 0 11px 25px 0 rgba(128, 115, 143, 0.45);

        padding: 25px 30px;

        .btn-back {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 20px;

          span {
            color: #4A20F0;
            font-size: 18px;
            font-weight: 600;
            text-transform: capitalize;
          }
        }
      }

      .custom-form {
        display: flex;
        flex-direction: column;
        height: calc(100% - 90px);
        justify-content: space-between;

        padding: 0px 5px 5px;

        .messages-container {
          max-height: none;
        }

        form {
          min-height: 190px;

          .input-container {
            margin-bottom: 10px;
          }

          .button.space-between {
            flex-direction: row;
            align-items: center;

            > div, > button {
              flex: 1;
            }

            > button, .attach-button {
              flex: 1;
              padding: 28px 0;
            }
          }

          .files-container {
            padding: 5px 0 0;
          }
        }
      }
    }
  }

  @media (max-width: 800px) {

    .register-container {
      padding: 150px 25px 0;

      .information {
        padding-top: 0;
      }
    }
  }
}
